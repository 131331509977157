import React from "react";
import Layout from "../components/Layout/Layout";
import * as styles from "./blog.module.css";
import "../i18n";
import BlogThumbnail from "../components/BlogThumbnail/BlogThumbnail";
import {useTranslation} from "react-i18next";
import Seo from "../components/shared/Seo";

export default function Blog() {
    const {t, i18n} = useTranslation();
    const lang = i18n.language;
    let blogposts = require("../data/en_blogs.json");
    if (lang === 'fr') {
        const blogposts_fr = require("../data/fr_blogs.json");
        blogposts = blogposts.map(blogpost => {
            const fr_blog = blogposts_fr.find(blogpost_fr => blogpost_fr.id === blogpost.id)
            return fr_blog ?? blogpost
        })
    }
    return (<Layout>
        <Seo title="Blog"
             description="News, tip & trends for the recruitment industry. Discover our blog for regular updates"
             canonicalPath="/blog"/>
        <div className={styles.blogs_container}>
            <div className={styles.title}>
                <h1 className={styles.main_title}>{t("blog.title")}</h1>
                <h2 className={styles.sub_title}>Hiringmaster blog</h2>
            </div>
            <div className={styles.blogs}>
                {blogposts && blogposts.map(blogpost => <BlogThumbnail blogpost={blogpost} key={blogpost.slug}/>)}
            </div>
        </div>
    </Layout>);
}
