import React from "react";
import * as styles from "./BlogThumbnail.module.css";
import {Link} from "gatsby";
import {useTranslation} from "react-i18next";

export default function BlogThumbnail({blogpost}) {
    const {i18n} = useTranslation();
    const formatDateInEnglish = (date) => {
        const formattedDate = new Date(date);
        return `${formattedDate.toLocaleString("en-us", {month: "long"})} ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`;
    };
    const formatDateInFrensh = (date) => {
        const formattedDate = new Date(date);
        return `${formattedDate.toLocaleString("fr-FR", {month: "long"})} ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`;
    };
    const lang = i18n.language;
    return (<div className={styles.thumbnail_container}>
        <div className={styles.img}>
            <Link className={styles.imgLink} to={blogpost.slug}>
                <img src={`/images/${blogpost.thumbnail}`}/>
            </Link>
        </div>
        <div className={styles.section}>
            <h1 className={styles.section_title}>{blogpost.section}</h1>
            <p className={styles.separator}>•</p>
            <p className={styles.reading_time}>{blogpost.reading_time}</p>
        </div>
        <div className={styles.title}><Link to={blogpost.slug}>{blogpost.title}</Link></div>
        <div
            className={styles.date}>{lang === "en" ? formatDateInEnglish(blogpost.createdAt) : formatDateInFrensh(blogpost.createdAt)}</div>
    </div>);
}
